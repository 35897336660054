*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.aspect-w-3 {
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 3;
  position: relative;
}

.aspect-w-3 > * {
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;
}

.aspect-h-2 {
  --tw-aspect-h: 2;
}

.tw-link {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  text-decoration-line: underline;
}

.tw-link:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.tw-navbar {
  --tw-bg-opacity: 1;
  background-color: rgb(54 54 64 / var(--tw-bg-opacity));
}

@media (min-width: 1024px) {
  .tw-navbar {
    display: flex;
  }
}

.tw-container {
  width: 100%;
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

.tw-container {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
}

@media (min-width: 1024px) {
  .tw-container {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.tw-container-empty {
  width: 100%;
}

@media (min-width: 640px) {
  .tw-container-empty {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container-empty {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container-empty {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container-empty {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container-empty {
    max-width: 1536px;
  }
}

.tw-navbar-item {
  height: 100%;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding: 1rem;
  display: block;
}

.tw-navbar-item:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 1024px) {
  .tw-navbar-item {
    align-items: center;
    padding: 1rem .875rem;
    display: flex;
  }
}

.tw-navbar-item.is-active {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.tw-navbar-brand {
  height: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.tw-navbar-logo {
  height: 2.25rem;
  object-fit: contain;
}

.tw-navbar-meny {
  height: 100%;
  display: none;
}

@media (min-width: 1024px) {
  .tw-navbar-meny {
    align-items: center;
    display: flex;
  }
}

.tw-navbar-meny.is-active {
  display: block;
}

.tw-navbar-mobile {
  height: 100%;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding: 1rem;
  display: block;
}

@media (min-width: 1024px) {
  .tw-navbar-mobile {
    display: none;
  }
}

.tw-footer {
  width: 100%;
}

@media (min-width: 640px) {
  .tw-footer {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-footer {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-footer {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-footer {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-footer {
    max-width: 1536px;
  }
}

.tw-footer {
  max-width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(54 54 64 / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding: 1rem;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.-top-9 {
  top: -2.25rem;
}

.z-50 {
  z-index: 50;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.m-2 {
  margin: .5rem;
}

.m-1 {
  margin: .25rem;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mt-auto {
  margin-top: auto;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-auto {
  margin-left: auto;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-8 {
  height: 2rem;
}

.h-6 {
  height: 1.5rem;
}

.h-full {
  height: 100%;
}

.h-12 {
  height: 3rem;
}

.h-5 {
  height: 1.25rem;
}

.h-9 {
  height: 2.25rem;
}

.h-96 {
  height: 24rem;
}

.min-h-screen {
  min-height: 100vh;
}

.w-8 {
  width: 2rem;
}

.w-6 {
  width: 1.5rem;
}

.w-full {
  width: 100%;
}

.w-5 {
  width: 1.25rem;
}

.w-screen {
  width: 100vw;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-full {
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.self-end {
  align-self: flex-end;
}

.justify-self-end {
  justify-self: end;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-bl {
  border-bottom-left-radius: .25rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-0 {
  border-width: 0;
}

.border-8 {
  border-width: 8px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-opacity-60 {
  --tw-border-opacity: .6;
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 204 21 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-tw-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(54 54 64 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-opacity-70 {
  --tw-bg-opacity: .7;
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-1 {
  padding: .25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-2 {
  padding: .5rem;
}

.p-5 {
  padding: 1.25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.leading-none {
  line-height: 1;
}

.leading-relaxed {
  line-height: 1.625;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.active\:border-gray-700:active {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

@media (min-width: 768px) {
  .md\:col-start-1 {
    grid-column-start: 1;
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:block {
    display: block;
  }

  .md\:grid {
    display: grid;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

@media (min-width: 1280px) {
  .xl\:w-1\/4 {
    width: 25%;
  }
}

/*# sourceMappingURL=tailwind.css.map */
