@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .tw-link {
    @apply text-gray-600 underline hover:text-gray-900 cursor-pointer
  }
  .tw-navbar {
    @apply bg-tw-dark lg:flex
  }
  .tw-container {
    @apply container mx-auto relative block lg:flex lg:items-center lg:justify-between
  }
  .tw-container-empty {
    @apply container
  }

  .tw-navbar-item {
    @apply block lg:flex lg:items-center px-4 py-4 lg:px-3.5 lg:py-4 text-white hover:bg-gray-800 hover:text-white h-full
  }
  .tw-navbar-item.is-active {
    @apply bg-gray-800
  }

  .tw-navbar-brand {
    @apply flex items-center justify-between h-full
  }
  .tw-navbar-logo {
    @apply object-contain h-9
  }

  .tw-navbar-meny {
    @apply hidden lg:flex lg:items-center h-full
  }
  .tw-navbar-meny.is-active {
    @apply block
  }

  .tw-navbar-mobile {
    @apply block lg:hidden cursor-pointer px-4 py-4 text-white h-full
  }

  .tw-footer {
    @apply container p-4 bg-tw-dark text-white text-center max-w-full
  }
}
